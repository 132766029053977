* {
  font-family: "Open Sans", sans-serif;
}

.h2-feature {
  font-size: 1.5rem;
  font-weight: bold;
}

#front-page {
  min-height: 100vh;
}

.carousel-inner * {
  margin: auto;
}

@media (max-width: 768px) {
  .carousel-inner > div > div {
    max-width: calc(100% - 140px);
  }
}

.h1-title,
.h2-title,
.h3-title {
  font-family: "Poppins", sans-serif;
}

.h1-title {
  font-size: 2.25rem;
  font-weight: bold;
}

.h2-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.h3-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.h2-content-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.h3-content-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.h4-content-title {
  font-size: 1rem;
  font-weight: 600;
}

.grey-label {
  font-size: 0.75rem;
  color: #aaaaaa;
}
