#hero {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero-statement {
  margin: 0 64px 0 0;
  padding: 0 64px 0 0;
  font-family: "Poppins", sans-serif;
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 4rem;
}

.hero-statement {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}

.hero-image {
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
}

@media (max-width: 1282px) {
  #hero {
    display: block;
    text-align: center;
  }

  .hero-statement {
    margin: 0;
    padding: 0;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .hero-image {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-statement {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
