* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}
body {
  background: linear-gradient(
    109.6deg,
    rgb(9, 9, 121) 11.2%,
    rgb(144, 6, 161) 53.7%,
    rgb(0, 212, 255) 100%
  );
  background-repeat: no-repeat;
}
