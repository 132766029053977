.category {
  border: 1px solid #aaa;
  border-radius: 25px;
  padding: 16px;
  background-color: #444;
  max-width: 556px;
  margin: auto;
}

.category:hover {
  background-color: #555;
  cursor: pointer;
}
.category-image {
  width: 70px;
  height: 70px;
}
