.card-bg:hover {
  background-color: #555555;
  cursor: pointer;
}

.card-bg {
  background-color: #444444;
  border-radius: 25px;
  width: 630px;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
}

.content {
  font-size: 1rem;
  font-weight: normal;
}

#project > img {
  border-radius: 25px;
  max-width: 150px;
  max-height: 150px;
}

@media (max-width: 768px) {
  .card-bg {
    width: calc(100%- 140px);
  }

  #project {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .card-bg {
    width: calc(150px + 32px);
  }

  #project {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #project > img {
    margin: auto auto 16px !important;
  }

  .title {
    text-align: center;
    margin-bottom: 16px;
  }

  .content {
    display: none;
  }
}
