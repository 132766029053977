.solid-border {
  border: 1px solid #fff;
  padding: 16px;
  border-radius: 25pt;
  background-color: #444;
  max-width: 556px;
  margin: 0 auto;
}

.solid-border img {
  height: 70px;
}

.solid-border p {
  text-align: left;
}
